/* global.css */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  body {
    font-family: Arial, sans-serif;
    background-color: #fbf8f3;
  }
  
  html {
    scroll-behavior: smooth;
  }